import React from 'react';
import './Sessao.css';
import Map from '../Map';

const Sessao = ({ children, onStateClick, cardsVisiveis }) => {
  return (
    <section className={`all-box ${!cardsVisiveis ? 'centered' : ''}`}>
      <div className='container_map'>
        <Map onStateClick={onStateClick} />
      </div>
      {cardsVisiveis && (
        <div className="scrolling-box">
          {children}
        </div>
      )}
    </section>
  );
};

export default Sessao;
