import React, { useState } from 'react';
import Banner from './components/Banner';
import Card from './components/Card';
import Header from './components/Header';
import Sessao from './components/Sessao';
import candidatos_json from './json/novocand.json';
import Card_Erro from './components/Card_Error';
import Footer from './components/Footer';
import Sep from './components/Sep';
import './App.css'

const App = () => {
  const [filtro, setFiltro] = useState({ estado: '', cidade: '' });
  const [cardsVisiveis, setCardsVisiveis] = useState(false);

  const handleSaveData = (data) => {
    setFiltro(data);
    setCardsVisiveis(true); // Tornar os cards visíveis após a pesquisa
  };

  const handleStateClick = (estado) => {
    setFiltro({ ...filtro, estado });
    setCardsVisiveis(true); // Tornar os cards visíveis após clicar no estado do mapa
  };

  // Função para filtrar os candidatos com base no estado e cidade selecionados
  const candidatosFiltrados = candidatos_json.filter(candidato =>
    (filtro.estado === '' || candidato.Estado === filtro.estado) &&
    (filtro.cidade === '' || candidato.Cidade === filtro.cidade)
  );

  // Ordena os candidatos pelo nome de A a Z
  const candidatosOrdenados = candidatosFiltrados.sort((a, b) => {
    const nomeA = a.Nome || '';  // Substitui null ou undefined por uma string vazia
    const nomeB = b.Nome || '';  // Substitui null ou undefined por uma string vazia

    return nomeA.localeCompare(nomeB);
  });

  return (
    <div className="App">
      <Header />
      <Banner onSave={handleSaveData} />
      <Sessao onStateClick={handleStateClick} cardsVisiveis={cardsVisiveis}>
        <div className={`card-container ${!cardsVisiveis ? 'disabled' : ''}`}>
          {cardsVisiveis && (
            candidatosOrdenados.length === 0 ? (
              <Card_Erro message="Nenhum candidato encontrado para o filtro selecionado." />
            ) : (
              candidatosOrdenados.map((candidato, index) => (
                <Card
                  key={index}
                  nome={candidato.Nome}
                  cidade={candidato.Cidade}
                  estado={candidato.Estado}
                  partido={candidato.Partido}
                  genero={candidato["Gênero"]}
                  raca={candidato["Raça"]}
                  faixaEtaria={candidato["Faixa-etária"]}
                  candidato={candidato["Pr\u00e9 Candidato\/a"]}
                  redes={candidato.Redes}
                  selos={candidato.Selos}
                  foto={candidato.Foto}
                />
              ))
            )
          )}
        </div>
      </Sessao>
      <Sep />
      <Footer />
    </div>
  );
}

export default App;
