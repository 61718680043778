import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <article className="logo-topic">
        <div className="logo">
          <a href="https://ypykuera.org.br/"><img className="footer_logo" src="./images/logofot.png" alt="Logo" /></a>
        </div>
        <div className="span-info">
          <span>contato@ypykuera.org.br</span>
          <span>2023 | Núcleo Ypykuéra de Cidadania e Políticas Públicas</span>
        </div>
      </article>
      <section className="topic-info">
        <div>
          <div className="button-boletim" ><a href="">Inscreva-se em nosso boletim</a></div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
